<template>
  <v-card v-if="usuariosadd">
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.apellido"
                label="Apellido"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.email"
                :rules="emailRules"
                label="Correo electrónico"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddUsuarios',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        search: '',
        reset: false,
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ],
        editedItem: {
          id: 0,
          nombre: '',
          apellido: '',
          email: '',
        },
      }
    },
    computed: {
      usuariosadd: {
        get () {
          return this.$store.state.usuarios.usuariosadd
        },
      },

      loading: {
        get () {
          return this.$store.state.usuarios.loading
        },
      },
    },
    async beforeMount () {
      await this.$store.commit('usuarios/setRegister', false)
    },
    methods: {
      volver () {
        this.editedIndex = -1
        this.$refs.form.reset()
        this.$store.commit('usuarios/setRegister', false)
      },
      submit () {
        if (this.$refs.form.validate()) {
          const data = {
            index: this.editedIndex,
            id: this.editedItem.id,
            nombre: this.editedItem.nombre,
            apellido: this.editedItem.apellido,
            email: this.editedItem.email,
            roles: 'ROLE_ADMIN',
          }

          if (this.editedIndex < 0) {
            this.$store.dispatch('usuarios/addUsuario', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('usuarios/updateUsuario', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                if (response.data.email) {
                  setTimeout(() => {
                    this.$store.dispatch('logout')
                  }, 3000)
                }
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
